//首页相关
import { $http } from '../plugins/axios'
const { post, get } = $http


//分页查询车型信息
export const pageAllCar = (pageNum, pageSize) => {
    return get(`/api/system/enterpriseUser/pageAllCar?pageNum=${pageNum}&pageSize=${pageSize}`)
}
//查询平台服务
export const pageAllCarService = () => {
    return get('/api/system/enterpriseUser/pageAllCarService')
}
//计算距离
export const distanceCalculation = (originsAddress, destinationsAddress) => {
    return get(
        `/system/enterpriseOrder/distanceCalculation?originsAddress=${originsAddress}&destinationsAddress=${destinationsAddress}`
    )
}
//获取用车时长列表
export const listCharterTime = () => {
    return get('/api/system/enterpriseUser/listCharterTime')
}
//专车生成订单
export const orderGeneration = (params) => {
    return post('/system/enterpriseOrder/orderGeneration', params)
}
//专车生成订单
export const charteredBusOrderGeneration = (params) => {
    return post('/system/enterpriseOrder/charteredBusOrderGeneration', params)
}
//分页查询我的订单
export const pageAllEnterpriseOrder = (pageNum, pageSize, orderStatus, searchType, searchContent) => {
    return get(
        `/system/enterpriseOrder/pageAllEnterpriseOrder?pageNum=${pageNum}&pageSize=${pageSize}&orderStatus=${orderStatus}&searchType=${searchType}&searchContent=${searchContent}`
    )
}
//查询订单详情信息
export const getEnterpriseOrderDetail = (id) => {
    return get(`/system/enterpriseOrder/getEnterpriseOrderDetail?id=${id}`)
}
//取消订单
export const cancelOrder = (id) => {
    return post(`/system/enterpriseOrder/cancelOrder?id=${id}`)
}
//删除订单
export const delOrder = (id) => {
    return post(`/system/enterpriseOrder/delOrder?id=${id}`)
}
//新-分页查询车型信息 专车
export const pageAllCarAddress = (params) => {
    return get(
        `/api/system/enterpriseUser/pageAllCarByAddress`,params
    )
}
//新-分页查询车型信息 包车
export const listCharterTimeNew = (params) => {
    return get('/api/system/enterpriseUser/pageAllCarByTime',params)
}
//新-获取区号列表
export const getAreaCodeList = () => {
	return get(`/fangshan/dm-order/listAllCode`)
}
//新--地址搜索范围中文
export const addressSearch = (address) => {
    return get(`/fangshan/dm-new/getAddressByGoogle?name=${address}`)
}
//新--地址搜索范围英文
export const addressSearchEnglish = (address) => {
    return get(`/fangshan/dm-new/getAddressByGoogleForEnglish?name=${address}`)
}
// export const addressSearch = (address) => {
//     return get(`/fangshan/dm-new/getAddressByAddress?address=${address}`)
// }

//新--微信支付
export const setWxPay = (params) => {
    return post(`/fangshan/pay/codePay`,params)
}
//新--支付宝支付
export const setzfbPay = (params) => {
    return post(`/fangshan/pay/AlipayScanPay`,params)
}
//新--判断是否支付
export const setCodePayEnd = (params) => {
    return get(`/fangshan/pay/codePayEnd`,params)
}
//新--超时说明 包车
export const getOverTimeInfo = (params) => {
    return get(`/fangshan/dm-user/platFormTextType`,params)
}