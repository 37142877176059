<template>
    <div style="height: 14px; width: 100%;"></div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>