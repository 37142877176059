<template>
  <div class="inputs f-row-b-c">
    <el-input prefix-icon="el-icon-location-outline" v-model="value" :placeholder="placeholder" @input="changeAddress"
      @blur="blurAddress" clearable></el-input>
    <div class="address-box" :style="{ 'width': width }" v-if="isSearch">
      <div :loading="isLoading">
        <div class="box-list" v-if="searchList && searchList.length > 0">
          <div class="box-item f-row-s" v-for="(item, index) in searchList" :key="index"
            @click="currentAddress(item.mainText,item.secondaryText)">
            <div>
              <i class="el-icon-location-outline"></i>
            </div>
            <div class="f-col-s-c" style="margin-left: 10px;">
              <div class="main ell-line1">{{ item.mainText }}</div>
              <div class="all">{{ item.secondaryText }}</div>
            </div>

          </div>
        </div>
        <div class="no-result f-col-c-c" v-else>
          {{ $t('info43') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addressSearch,
  addressSearchEnglish
} from '@/api/home'
export default {
  name: "AddressFrame",
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      value: '',
      searchList: [],
      isSearch: false,
      isLoading: false,
      language: 'zh',
    };
  },
  created() {
    if (localStorage.getItem('language')) {
      this.language = localStorage.getItem('language')
    }
  },
  methods: {
    //地址查找
    changeAddress(val) {
      if (!!val) {
        this.isSearch = true;
        this.getAddressList(val);
      } else {
        this.isSearch = false;
        this.searchList = [];
      }
    },
    //获取相似列表
    getAddressList(val) {
      if (this.language == 'zh') {
        addressSearch(val).then(res => {
          if (res.code === 200) {
            this.isSearch = true;
            this.isLoading = true;
            this.searchList = res.data;
          }
        }).finally(() => {
          this.isLoading = false;
        })
      } else if (this.language == 'en') {
        addressSearchEnglish(val).then(res => {
          if (res.code === 200) {
            this.isSearch = true;
            this.isLoading = true;
            this.searchList = res.data;
          }
        }).finally(() => {
          this.isLoading = false;
        })
      }

    },
    //选择当前地址
    currentAddress(val,all) {
      this.value = all + val;
      this.$emit('inputValue', this.value);
      this.isSearch = false;
    },
    blurAddress() {
      const obj = this.searchList.find((item) => { this.value == item.secondaryText + item.mainText })
      if (!obj) {
        this.value = ''
      }
      setTimeout(() => {
        this.isSearch = false;
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
.f-row-s {
  display: flex;
  justify-content: flex-start;
}

.f-col-s-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.f-col-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ell-line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep.el-input__inner {
  border: none !important;
}

.inputs {
  position: relative;
  min-width: 256px;

  input {
    width: 100%;
  }
}

.address-box {
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 1;
  width: 266px;
  min-height: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 5px #ddd;

  .address-load {
    height: 100px;
  }

  .box-list {
    .box-item {
      padding: 5px 10px;
      line-height: 35px;
      font-size: 15px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }

    .main,
    .all {
      width: 380px;
    }

    .all {
      line-height: 26px;
      font-size: 15px;
      color: #666;
      white-space: normal;
      overflow-wrap: break-word;
    }
  }

  .no-result {
    line-height: 100px;
    font-size: 15px;
    color: #666;
  }
}
</style>