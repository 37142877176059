<template>
    <div class="title">
        <slot></slot>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title {
    border-left: 6px solid #0D5E6B;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    padding: 0 10px;
}
</style>