import Vue from "vue";

import VHeader from "@/components/VHeader.vue";
import VGap from "@/components/VGap.vue";
import VSidebar from '@/components/VSidebar.vue'
import VOrder from '@/components/VOrder.vue'
import VTitle from '@/components/VTitle.vue'






Vue.component('VHeader', VHeader);
Vue.component('VGap', VGap);
Vue.component('VSidebar', VSidebar);
Vue.component('VOrder', VOrder);
Vue.component('VTitle', VTitle);
