//登录注册相关
import { $http } from '../plugins/axios'
const { post, get } = $http

//注册
export const register = (params) => {
    return post('/api/system/enterpriseUser/register', params)
}
//校验验证码是否正确
export const checkIdentifyCode = (params) => {
    return post('/api/system/enterpriseUser/checkIdentifyCode', params)
}
//发送验证码
export const sendAccountCode = (params) => {
    return post('/api/system/enterpriseUser/sendAccountCode', params)
}
//登录
export const appLogin = (params) => {
    return post('/api/system/enterpriseUser/appLogin', params)
}
//忘记密码
export const forgotPassword = (params) => {
    return post('/api/system/enterpriseUser/forgotPassword', params)
}